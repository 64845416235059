import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/login/Login";
import Signup from "./components/Signup";
import Home from "./components/Home";
import Welcome from "./components/Welcome";
import UserProfile from "./components/UserProfile";
import PasswordReset from "./components/PasswordReset";
import ResetSuccess from "./components/ResetSuccess";
import VideoContainer from "./components/video/VideoContainer";
import PrivateRoutes from "./utils/PrivateRoutes";
import { FinishedDancingModal } from "./components/emotion/FinishedDancingModal";

function App() {
  return (
    <>
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route path="/welcome" element={<Welcome />} />
        <Route path="/video" element={<VideoContainer />} />
        <Route path="/profile" element={<UserProfile />} />
      </Route>

      <Route path="/" exact element={<Home />} />
      <Route path="/login/:userId" element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/passwordreset" element={<PasswordReset />} />
      <Route path="/register" element={<Signup />} />
      <Route path="/reset/:token" element={<ResetSuccess />} />
    </Routes>
    </>
  );
}

export default App;
