import React, { useEffect, useState } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { ensureAuth } from "./usersAPI";

const PrivateRoutes = () => {
  const { pathname } = useLocation(); // current route
  const [auth, setAuth] = useState();
  const [isLoading, setIsLoading] = useState(true);

  async function getAuth() {
    setIsLoading(true);
    const response = await ensureAuth();
    // console.log("privateroutes auth: ", response);
    setAuth(response?.success || false);
    setIsLoading(false);
  }

  useEffect(() => {
    getAuth();
  }, [pathname]); // dependancy will be the route we are in

  if (isLoading) {
    return <div className="">Loading...</div>;
  }
  return auth ? <Outlet /> : <Navigate to={"/login"} />;
};

export default PrivateRoutes;