import React from "react";
import ReactPlayer from "react-player/youtube";
import { expandBackgroundStartImages } from "../../utils/utils";
import "../../assets/css/bootstrap.scoped.css";
// Using React-Player from cookpete/react-player: https://github.com/CookPete/react-player

export default class YouTubePlayer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      playing: this.props.playing,
      displayCountdown: true,
      countdownIndex: 0,
      isCameraVideoPopUp: this.props.isCameraVideoPopUp,
      isMuted: true,
    };
  }
  countdownImages = [
    "StartVideoBelow.png",
    "5.png",
    "4.png",
    "3.png",
    "2.png",
    "1.png",
  ];

  componentDidUpdate(prevProps, prevState) {
    console.log(`state.playing=${this.state.playing}`);
    
    //console.log(`COMPONENT UPDATED oldProp(${prevProps.isCameraVideoPopUp}) -> newProp(${this.props.isCameraVideoPopUp})`);
    if (prevProps.isCameraVideoPopUp !== this.props.isCameraVideoPopUp) {
      this.setState({ isCameraVideoPopUp: true }, () => {
        //console.log("<YoutubePlayer>PROP CHANGED!");
      });
    }
    if (this.props.isCameraVideoPopUp) {
      expandBackgroundStartImages();
      //console.log('START IMGS EXPANDED');
    }

    setTimeout(() => {
      
    }, 100);
  }

  // shouldComponentUpdate(nextProps) {
  // Compare the current props with the next props
  // Decide whether to update based on your logic
  // if (this.props.isCameraVideoPopUp === nextProps.isCameraVideoPopUp) {
  //   return false; // Prevent update
  // }
  // return true; // Allow update (always)
  // }

  loopCountdown = (videoStarted) => {
    const startIndex = videoStarted ? 3 : 1;
    let x = 0;
    for (let i = startIndex; i < this.countdownImages.length; i++) {
      setTimeout(() => {
        this.setState({ countdownIndex: i });
      }, x * 1000);
      x++;
    }
    setTimeout(
      () => {
        this.setState({ displayCountdown: false });
      },
      videoStarted ? 3 * 1000 : 5 * 1000
    );
  };

  setPlaying = (videoStarted) => {
    this.setState({ playing: true });
    // this.setState({ playing: false });

    if (!this.state.playing) {
      this.loopCountdown(videoStarted);
      setTimeout(
        () => {
          this.setState({
            playing: true,
          });
        },
        videoStarted ? 3 * 1000 : 5 * 1000
      );
    } else {
      this.setState({
        playing: false,
        displayCountdown: true,
        countdownIndex: 0,
      });
    }
  };

  handleEnded = (event) => {
    this.props.goToNextVideo(event);
  };

  handleStart = (event) => {  
    this.props.logVideoStart(event);
    if (this.state.isMuted) {
      this.setState({
        isMuted: false,
      });
    }
    //console.log("handleStart in vimeoPlayer: ", event);
  };

  handleReady = (event) => {
    console.log('PLAYER READY');
    // this.setState({ playing: true });
  }

  render() {
    //console.log(`<YoutubePlayer>Is Camera Pop Up? ${this.props.isCameraVideoPopUp}`);

    return (
      <>
        {/* <img id="screen-img" src={require("../../assets/img/Bg_CountDown_withTarget.png")} />
        <img id="start-6" src={require("../../assets/img/StartVideoBelow.png")} />
        <img id="start-5" src={require("../../assets/img/5.png")} hidden/>
        <img id="start-4" src={require("../../assets/img/4.png")} hidden/>
        <img id="start-3" src={require("../../assets/img/3.png")} hidden/>
        <img id="start-2" src={require("../../assets/img/2.png")} hidden/>
        <img id="start-1" src={require("../../assets/img/1.png")} hidden/> */}
        {this.state.displayCountdown ? (
          // <img
          //   id="screen-img"
          //   // style={{
          //   //   width:`${this.props.videoWidth}px`,
          //   //   height:`${this.props.videoHeight}px`
          //   // }}
          //   style={{
          //     width: this.props.isCameraVideoPopUp
          //       ? `100%`
          //       : this.props.videoWidth,
          //     height: `100%`,
          //   }}
          //   src={require("../../assets/img/Bg_CountDown_withTarget.png")}
          // />
          <div
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              background: '#00000066',
              backdropFilter: 'blur(10px)',
              transition: 'backdrop-blur 1s'
            }}>
          </div>
        ) : null}
        {this.state.displayCountdown ? (
          <>
            <img
            id="start-img"
            src={require(`../../assets/img/${
              this.countdownImages[this.state.countdownIndex]
            }`)}
            style={{zIndex: '2'}}
          />
          {/* lines */}
          <div style={{position: 'absolute',width: '100vw', height: '4px', background: '#cc336688', boxShadow: '0 0 50px #cc3366',  zIndex: 1}}></div>
          <div style={{position: 'absolute',width: '4px', height: '100vh', background: '#cc336688',  boxShadow: '0 0 50px #cc3366', zIndex: 1}}></div>
          {/* circles */}
          <div style={{position: 'absolute',width: '65vh', height: '65vh', background: '#00000000', boxShadow: '0 0 -50px #ffffff88' , border: '4px solid #ffffff77', borderRadius: '50%',  zIndex: 1}}></div>
          <div style={{position: 'absolute',width: '60vh', height: '60vh', background: '#00000000', boxShadow: '0 0 50px #ffffff88', border: '4px solid #ffffff77', borderRadius: '50%',  zIndex: 1}}></div>
          {/* background */}
          <div style={{position: 'absolute',width: '100vw', height: '100vh', background: '#ffffff11',  zIndex: 0}}></div>
          </>
        ) : null}
        <ReactPlayer
          id="react-player"
          playing={this.state.playing}
          playsinline={true}
          muted={this.state.isMuted}
          url={this.props.youTubeUrl}
          onEnded={this.handleEnded}
          onStart={this.handleStart}
          onReady={this.handleReady}
          //  videoId={this.props.videoId}
          config={{
            youtube: {
              playerVars: {
                // listType: "playlist",
                // list: this.props.videoId,
                modestbranding: 1,
                disablekb: 1,
                autoplay: 0,
                controls: 0,
              },
            },
            file: {
              attributes: {preload: 'auto'}
            }
          }}
          width={`${this.props.videoWidth}px`}
          height={`${this.props.videoHeight}px`}
        />

        {/* <button onClick={() => setPlaying(!playing)}>Play</button> */}
      </>
    );
  }
}
