import React, { useEffect, useState, useRef } from "react";
import circle_15 from "../../assets/img/circle_15.png";
import circle_30 from "../../assets/img/circle_30.png";
import circle_45 from "../../assets/img/circle_45.png";
import circle_60 from "../../assets/img/circle_60.png";
import circle from "../../assets/img/circle.png";
import '../../css/energy.css';

const EnergyWidget = ({handleEnergyClick}) => {

  let energy = "";
  let duration = 0;
  const [EnergyWidgetLayout, setEnergyWidgetLayout] = useState('row');

  const card1Ref = useRef();
  const card2Ref = useRef();
  const card3Ref = useRef();
  const how1Ref = useRef();
  const how2Ref = useRef();
  const how3Ref = useRef();
  const center3Ref = useRef();
  const emot_overlayRef = useRef();
  const emot_overlay2Ref = useRef();
  const circle1Ref = useRef();
  const circle2Ref = useRef();
  const circle3Ref = useRef();

  function flip(e, id) {
    card1Ref.current.classList.remove("flipped");
    card2Ref.current.classList.remove("flipped");
    card3Ref.current.classList.remove("flipped");

    if (id === 1) {
      card1Ref.current.classList.toggle("flipped");
      energy = "low";
    } else if (id === 2) {
      card2Ref.current.classList.toggle("flipped");
      energy = "medium";
    } else {
      card3Ref.current.classList.toggle("flipped");
      energy = "high";
    }

    // how1Ref.current.style.display = "none";
    how2Ref.current.style.opacity = 1;
    // how3Ref.current.style.display = "none";

    if (id === 1) {
      // how1Ref.current.style.display = "block";
      energy = "low";
    } else if (id === 2) {
      // how2Ref.current.style.display = "block";
      energy = "medium";
    } else {
      // how3Ref.current.style.display = "block";
      energy = "high";
    }
    localStorage.setItem("speed", id);
  }

  const center3Click = (e) => {
    if (typeof e.target !== "undefined") {
      center3Ref.current.style.display = "none";
      emot_overlay2Ref.current.style.display = "block";
    }
  };

  function pick(e, which) {
    switch (which) {
      case 15:
        circle1Ref.current.src = circle_15;
        circle2Ref.current.src = circle_15;
        circle3Ref.current.src = circle_15;
        duration = 15;
        handleEnergyClick(energy, duration);
        break;
      case 30:
        circle1Ref.current.src = circle_30;
        circle2Ref.current.src = circle_30;
        circle3Ref.current.src = circle_30;
        duration = 30;
        handleEnergyClick(energy, duration);
        break;
      case 45:
        circle1Ref.current.src = circle_45;
        circle2Ref.current.src = circle_45;
        circle3Ref.current.src = circle_45;
        duration = 45;
        handleEnergyClick(energy, duration);
        break;
      case 60:
        circle1Ref.current.src = circle_60;
        circle2Ref.current.src = circle_60;
        circle3Ref.current.src = circle_60;
        duration = 60;
        handleEnergyClick(energy, duration);
        break;
      default:
        circle1Ref.current.src = circle;
        circle2Ref.current.src = circle;
        circle3Ref.current.src = circle;
    }

    localStorage.setItem("time", which);
   
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize);
  }, [])

  function handleResize() {
    console.log('RERENDER');
    if (window.innerWidth > 910) {
      setEnergyWidgetLayout('row');
    } else {
      setEnergyWidgetLayout('column');
    }
  }

  return (
      <div id="horizon3" className="center horizon3">

        <div id="emot_overlay2">
          
          <div id="emot_text" className="textLabel white" style={{marginBottom: '10px'}}>
            Choose your Energy Level
          </div>

          {/* <span className="flip-table-top" id="how1" ref={how1Ref}>
            How Long?
          </span> */}

          <span className="flip-table-top" id="how2" ref={how2Ref}>
            How Long?
          </span>

          {/* <span className="flip-table-top" id="how3" ref={how3Ref}>
            How Long?
          </span> */}

          <div className="energy-level-container">

            <section className="flipcontainer">
              <div id="card1" className="card" ref={card1Ref}>
                <div className="frontCard">
                  <img
                    id="low"
                    onClick={(e) => flip(e, 1)}
                    src={require('../../assets/img/circle_energy_low.png')}
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="backCard">
                  <img
                    id="lowTime"
                    src={require('../../assets/img/circle.png')}
                    ref={circle1Ref}
                    useMap="#image-map"
                    className="time"
                    alt=""
                  />
                </div>
              </div>
              {/* <span className="emot_text_below">low</span> */}
            </section>

            <map name="image-map">
              <area
                alt=""
                onClick={(e) => pick(e, 15)}
                coords="10,0,94,93"
                shape="rect"
              />
              <area
                alt=""
                onClick={(e) => pick(e, 30)}
                coords="97,2,182,92"
                shape="rect"
              />
              <area
                alt=""
                onClick={(e) => pick(e, 60)}
                coords="12,170,92,94"
                shape="rect"
              />
              <area
                alt=""
                onClick={(e) => pick(e, 45)}
                coords="96,95,180,177"
                shape="rect"
              />
            </map>

            <section className="flipcontainer">
              <div id="card2" className="card" ref={card2Ref}>
                <div className="frontCard">
                  <img
                    id="medium"
                    onClick={(e) => flip(e, 2)}
                    src={require('../../assets/img/circle_energy_medium.png')}
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="backCard">
                  <img
                    id="mediumTime"
                    src={require('../../assets/img/circle.png')}
                    ref={circle2Ref}
                    useMap="#image-map"
                    className="time"
                    alt=""
                  />
                </div>
              </div>
              {/* <span className="emot_text_below">medium</span> */}
            </section>

            <section className="flipcontainer">
              <div id="card3" className="card" ref={card3Ref}>
                <div className="frontCard">
                  <img
                    id="high"
                    onClick={(e) => flip(e, 3)}
                    src={require('../../assets/img/circle_energy_high.png')}
                    alt=""
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="backCard">
                  <img
                    id="highTime"
                    src={require('../../assets/img/circle.png')}
                    ref={circle3Ref}
                    useMap="#image-map"
                    className="time"
                    alt=""
                  />
                </div>
              </div>
              {/* <span className="emot_text_below">high</span> */}
            </section>

          </div>

          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-around', width: '100%', fontSize: '20px'}}>
            <div>LOW</div>
            <div>MEDIUM</div>
            <div>HIGH</div>
          </div>
          
        </div>

      </div>
  );
};

export default EnergyWidget;
