import React, { useRef, useEffect, useState } from "react";
import "../../css/emotion.css";

import face_neutral from "../../assets/img/face_neutral.png";
import face_angry from "../../assets/img/face_angry.png";
import face_happy from "../../assets/img/face_happy.png";
import face_sad from "../../assets/img/face_sad.png";
import face_scared from "../../assets/img/face_scared.png";
import face_tired from "../../assets/img/face_tired.png";
import face_worried from "../../assets/img/face_worried.png";
//import circle_energy_low from "../images/circle_energy_low.png";
//import circle_energy_medium from "../images/circle_energy_medium.png";
//import circle_energy_high from "../images/circle_energy_high.png";
//import circle from "../images/circle.png";
//import circle_15 from "../images/circle_15.png";
//import circle_30 from "../images/circle_30.png";
//import circle_45 from "../images/circle_45.png";
//import circle_60 from "../images/circle_60.png";

const EmotionWidget = ({ handleEmotion }) => {
  const center3Ref = useRef();
  const face_statusRef = useRef();
  const emot_overlayRef = useRef();
  const emot_overlay2Ref = useRef();
  const hideScreenRef = useRef();
  

  const [hover, setHover] = useState(false);

  const handleChange = (event) => {
    //setRoomName(event.target.value);test
  };

  const emotionHistory = (event) => {
    // const result = usersAPI.saveEmotion({
    //   username: username,
    //   emotion: emotion,
    //   energy: energy,
    //   duration: duration,
    // });
    //console.log("Emotion saving result is: ", event);
  };

  const center3Click = (e) => {
    if (typeof e.target !== "undefined") {
      center3Ref.current.style.display = "none";
      hideScreenRef.current.style.zIndex = 0;
      emot_overlay2Ref.current.style.display = "block";
    }
  };

  const toggleHover = () => {
    setHover(!hover);
  };

  const hoverEff = (e) => {
    if (typeof e.target !== "undefined") {
      e.target.style.opacity = "1.0";
      switch (e.target.id) {
        case "btn_happy":
          face_statusRef.current.src = face_happy;

          break;
        case "btn_sad":
          face_statusRef.current.src = face_sad;

          break;
        case "btn_tired":
          face_statusRef.current.src = face_tired;

          break;
        case "btn_angry":
          face_statusRef.current.src = face_angry;

          break;
        case "btn_worried":
          face_statusRef.current.src = face_worried;

          break;
        case "btn_scared":
          face_statusRef.current.src = face_scared;

          break;
        default:
          face_statusRef.current.src = face_neutral;
      }
    }
  };

  const hoverOff = (e) => {
    if (typeof e.target !== "undefined") {
      e.target.style.opacity = "0.5";
      face_statusRef.current.src = face_neutral;
    }
  };

  const clickIt = (e) => {
    // console.log("the id:", e.target.id);
    handleEmotion(e.target.id);
   
  };

  function changeMsg(type, msg) {
  

    setTimeout(function () {
      center3Ref.current.style.display = "none";
      emot_overlay2Ref.current.style.display = "block";
    }, 4000);
  }

  return (
    // <div className="horizon">
    //   <div className="center2">
        <div
          id="emot_overlay"
          ref={emot_overlayRef}
          style={{
            backgroundColor: "#fa2d73",
            zIndex: 9999,
          }}
        >
          <div id="emot_text" className="textLabel white">
            How do you feel right now?
          </div>
          <br />
          <div id="emot_text_small" className="textLabel white">
            Click to choose your emotion
          </div>
          <div className="emotion-wheel-holder">
            <img
              id="bg_circle"
              src={require("../../assets/img/bg_circle.png")}
              alt="background"
            />
            <br />
            <img
              id="btn_happy"
              style={{ opacity: "0.5" }}
              src={require("../../assets/img/btn_happy.png")}
              alt="happy"
              onMouseOver={(e) => hoverEff(e)}
              onMouseOut={(e) => hoverOff(e)}
              onClick={(e) => clickIt(e)}
            />
            <img
              id="btn_sad"
              style={{ opacity: "0.5" }}
              src={require("../../assets/img/btn_sad.png")}
              alt="sad"
              onMouseOver={(e) => hoverEff(e)}
              onMouseOut={(e) => hoverOff(e)}
              onClick={(e) => clickIt(e)}
            />
            <br />
            <img
              id="btn_tired"
              style={{ opacity: "0.5" }}
              src={require("../../assets/img/btn_tired.png")}
              alt="tired"
              onMouseOver={(e) => hoverEff(e)}
              onMouseOut={(e) => hoverOff(e)}
              onClick={(e) => clickIt(e)}
            />
            <img
              id="btn_angry"
              style={{ opacity: "0.5" }}
              src={require("../../assets/img/btn_angry.png")}
              alt="angry"
              onMouseOver={(e) => hoverEff(e)}
              onMouseOut={(e) => hoverOff(e)}
              onClick={(e) => clickIt(e)}
            />
            <br />
            <img
              id="btn_worried"
              style={{ opacity: "0.5" }}
              src={require("../../assets/img/btn_worried.png")}
              alt="worried"
              onMouseOver={(e) => hoverEff(e)}
              onMouseOut={(e) => hoverOff(e)}
              onClick={(e) => clickIt(e)}
            />
            <img
              id="btn_scared"
              style={{ opacity: "0.5" }}
              src={require("../../assets/img/btn_scared.png")}
              alt="scared"
              onMouseOver={(e) => hoverEff(e)}
              onMouseOut={(e) => hoverOff(e)}
              onClick={(e) => clickIt(e)}
            />
            <br />
            <img
              id="face_neutral" //src={state.face_status}
              src={require("../../assets/img/face_neutral.png")}
              ref={face_statusRef}
              style={{ display: "inline" }}
              alt="unsure"
            />
          </div>
          
          {/* <img id="face_neutral" src="/img/face_neutral.png" style="display:inline;"
                alt="unsure" onclick="hideEmot();"/>*/}
        </div>
    //   </div>
    // </div>
  );
};

export default EmotionWidget;
