import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import "../assets/css/validation.scoped.css";
import * as usersAPI from '../utils/usersAPI';
import serializeForm from 'form-serialize';

function ResetSuccess(props) {

    const [message, setMessage] = React.useState(null);
    const [success, setSuccess] = React.useState(false);

    useEffect(() => {
        let isMounted = true;
        usersAPI.checkResetToken(window.location.href.substring(window.location.href.lastIndexOf("/") + 1)).then(
            data => {
                if (isMounted) {
                    if (data.success) {
                        setMessage(data.Message);
                        setSuccess(true);
                    }
                    else {
                        setMessage(data.errorMessage);
                        setSuccess(false);
                    }
                }
            });
        return () => { isMounted = false };
    }, []);

    function handleMessageChange(updated) {
        setMessage(updated);
    }

    return (
        <div>
            <nav className="navbar navbar-default navbar-fixed-top " >
                <div id="fb-root"></div>
                {/* Facebook Login */}
                <script src="https://connect.facebook.net/en_US/sdk.js?hash=f842513cfa7170782bb13b1dff0d0fa9&amp;ua=modern_es6"
                    async="" crossOrigin="anonymous"></script><script id="facebook-jssdk"
                        src="//connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v2.5"></script>
                <script>
                    {' (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s);js.id = id;js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5";fjs.parentNode.insertBefore(js, fjs);}(document, "script", "facebook-jssdk"));'}
                </script>
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle='collapse' data-target='#navbar' aria-expanded='false' aria-controls='navbar'>
                            <span className="sr-only">Toggle Navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/">Dance4healing</Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li><Link to="/register">Join the Party</Link>
                            </li>
                            <li className="active"><Link to="/login">Log In</Link>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right" />
                    </div>
                </div>
            </nav>

            <div style={{ marginTop: 75, textAlign: 'center', width: 380 }} className="container">
                <h1>Reset Password</h1>
                <p style={{ marginTop: 30, marginBottom: 30, fontFamily: '"Lato", sans-serif', fontStyle: 'italic', fontSize: 24, fontWeight: 300, letterSpacing: 1 }} >{message}</p>
                <Content success={success} change={handleMessageChange} />
            </div>
            <footer>
                <p className="smallfooter">Copyright © Dance4Healing 2023</p>
                <p className="smallfooter">Best viewed in <a href="https://www.google.com/chrome/browser/desktop/">Chrome web browser.</a>
                </p>
            </footer>
        </div>
    )

}

function Content(props) {
    const isSuccess = props.success;
    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = serializeForm(e.target, { hash: true });
        values['token'] = window.location.href.substring(window.location.href.lastIndexOf("/") + 1);
        const result = await usersAPI.doPassReset({
            values
        });
        if (result.success === true) {
            props.change(result.Message);
        }
        else {
            props.change(result.errorMessage);
        }
    };
    if (isSuccess) {
        return (
            <div>
                <div data-max-rows={1} data-size="xlarge" data-show-faces="false" data-auto-logout-link="false" className="fb-login-button" />
                <form onSubmit={handleSubmit}>
                    <div className="form-group tooltip">
                        <label style={{ marginLeft: '-180px', fontFamily: '"Roboto", sans-serif' }}>Enter your new password!</label><p style={{
                            display: 'inline-block',
                            color: 'yellow', marginLeft: '5px', fontSize: 'small'
                        }}></p>
                        <input id="password" name="password" type="password" placeholder="Enter Password" className="form-control container" /><span className="tooltiptext">Password requirements are: 8 characters min length.<br></br> At least 1 capital letter.<br></br> At least 1 number.<br></br> At least 1 symbol.</span>
                    </div>
                    <div className="form-group">
                        <label style={{ marginLeft: '-232px', fontFamily: '"Roboto", sans-serif' }}>Confirm Password</label>
                        <input id="password2" name="password2" type="password" placeholder="Confirm Password" className="form-control container" />
                    </div>

                    <button type="submit" name="submit" className="btn-lg register2" style={{ width: '100%' }}>Reset Password</button>
                    <p style={{ textAlign: 'center', paddingTop: 18, fontSize: 24, paddingBottom: 18, borderTopStyle: 'dotted', borderWidth: 2, marginTop: 24, fontFamily: '"Lato", sans-serif', fontWeight: 300, letterSpacing: 1 }}>No account and want to dance?</p><a href="/register" role="button" className="btn btn-lg login2">Join the Party</a>
                </form>
            </div>
        );
    }
    return (
        <div>

        </div>
    );
}

export default ResetSuccess;