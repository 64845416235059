import login_facebook from "../../assets/img/btn_login_facebook.png";
import login_twitter from "../../assets/img/btn_login_twitter.png";
import login_google from "../../assets/img/btn_login_google.png";
import login_linkedin from "../../assets/img/btn_login_linkedin.png";

const ResentVerification = ({ username }) => {
  return (
    <>
      <div
        className="confirmation-message"
        style={{
          display: "block",
          width: "200%",
          height: "100%",
          position: "fixed",
          bottom: "50%",
          right: "50%",
          transform: "translate(50%, 50%)",
          backgroundColor: "#CC3366",
          textAlign: "left",
        }}
      >
        <div
          className="confirmation-message-text"
          style={{
            position: "relative",
            top: "50%",
            transform: "translate(0, -50%)",
            marginLeft: "30%",
            marginRight: "30%",
            textAlign: "center",
            lineHeight: 2,
            fontSize: 50,
          }}
        >
          A verification email has been sent to {username}. Please check your
          email to verify.

      <div style={{ textAlign: "center" }}>
        <p>Meanwhile, follow us on these social media websites down below!</p>
        <div className="icons">
          <a href="https://www.facebook.com/danceforhealing">
            <img src={login_facebook} alt="Facebook" />
          </a>
          <a href="http://www.twitter.com/dance4healing">
            <img src={login_twitter} alt="Twitter" />
          </a>
          <a href="https://www.linkedin.com/company/dance4healing">
            <img src={login_linkedin} alt="LinkedIn" />
          </a>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default ResentVerification;
