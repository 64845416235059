const playlists = [
  {
    id: "1",
    videoId: "PL-_Xw0c-5GxQIDL2UPhB2m9ewS__Uq40O",
    url: "https://www.youtube.com/watch?v=fRsYIw2YUaw",
    // url:
    //   "https://www.youtube.com/playlist?list=PL-_Xw0c-5GxQIDL2UPhB2m9ewS__Uq40O",
    title: "Playlist Easy Breezy - Time 900",
    speed: "1",
    time: "15",
    videos: ["13", "18", "25", "7"],
    actualTime: "886",
  },
  {
    id: "2",
    videoId: "PL-_Xw0c-5GxRmSxc1D3NO-UdCInhMnh0g",
    url: "https://www.youtube.com/watch?v=fRsYIw2YUaw",
    // url:
    // "https://www.youtube.com/playlist?list=PL-_Xw0c-5GxRmSxc1D3NO-UdCInhMnh0g",
    title: "Playlist Easy Breezy - Time 1800",
    speed: "1",
    time: "30",
    videos: ["21", "22", "24", "18", "32"],
    actualTime: "1822",
  },
  {
    id: "3",
    videoId: "PL-_Xw0c-5GxQlPdUR-37DLeOz0E6_xzO",
    url: "https://www.youtube.com/watch?v=fRsYIw2YUaw",
    // url:
    //   "https://www.youtube.com/playlist?list=PL-_Xw0c-5GxQlPdUR-37DLeOz0E6_xzOL",
    title: "Playlist Easy Breezy - Time 2700",
    speed: "1",
    time: "45",
    videos: ["22", "23", "24", "25", "40", "1", "18", "12", "13", "32"],
    actualTime: "2644",
  },
  {
    id: "4",
    videoId: "PL-_Xw0c-5GxTqJ4C46qYk0DdM7xaNWmCC",
    url: "https://www.youtube.com/watch?v=fRsYIw2YUaw",
    // url:
    //   "https://www.youtube.com/playlist?list=PL-_Xw0c-5GxTqJ4C46qYk0DdM7xaNWmCC",
    title: "Playlist Easy Breezy - Time 3600",
    speed: "1",
    time: "60",
    videos: ["18", "1", "40", "2", "13", "21", "23", "24", "25", "22", "26", "32"],
    actualTime: "3541",
  },
  {
    id: "7",
    videoId: "PL-_Xw0c-5GxSHQnhVYRDcg-0BygohuQjK",
    url: "https://youtu.be/K-wr1y7Dt4U",
    // url:
    //   "https://www.youtube.com/playlist?list=PL-_Xw0c-5GxSHQnhVYRDcg-0BygohuQjK",
    title: "Playlist Mello Yellow - Time 900",
    speed: "2",
    time: "15",
    videos: ["37", "20", "2"],
    actualTime: "927",
  },
  {
    id: "8",
    videoId: "PL-_Xw0c-5GxSdz6o8gWSdNkZZdxuUzzSQ",
    url: "https://youtu.be/K-wr1y7Dt4U",
    title: "Playlist Mello Yellow - Time 1800",
    speed: "2",
    time: "30",
    videos: ["37", "28", "29", "3", "2"],
    actualTime: "1847",
  },
  {
    id: "9",
    videoId: "PL-_Xw0c-5GxTwVHj6ab8z-89lEZ3DY8KW",
    url: "https://youtu.be/x9_JfDAmI9g",
    title: "Playlist Mello Yellow - Time 2700",
    speed: "2",
    time: "45",
    videos: ["2", "3", "20", "40", "37", "39", "6", "29", "32"],
    actualTime: "2690",
  },
  {
    id: "10",
    videoId: "PL-_Xw0c-5GxRirFdEoHGIlVQCK-jy1bup",
    url: "https://youtu.be/x9_JfDAmI9g",
    title: "Playlist Mello Yellow - Time 3600",
    speed: "2",
    time: "60",
    videos: ["27", "28", "3", "4", "2", "20", "37", "14", "15", "40", "11", "7", "32"],
    actualTime: "3591",
  },
  {
    id: "11",
    url: "https://youtu.be/f4GaTklUzjg",
    title: "Playlist Dance Machine - Time 900",
    speed: "3",
    time: "15",
    videos: ["19", "28", "7", "8"],
    actualTime: "923",
  },
  {
    id: "12",
    url: "https://youtu.be/Sm4VU6XyFok",
    title: "Playlist Dance Machine - Time 1800",
    speed: "3",
    time: "30",
    videos: ["39", "19", "8", "10", "27", "33", "34", "32"],
    actualTime: "1799",
  },
  {
    id: "13",
    url: "https://youtu.be/fRsYIw2YUaw",
    title: "Playlist Dance Machine - Time 2700",
    speed: "3",
    time: "45",
    videos: ["38", "39", "18", "19", "29", "30", "35", "5", "7", "9", "32"],
    actualTime: "2752",
  },
  {
    id: "14",
    url: "https://youtu.be/Cpb5jIj_iBo",
    title: "Playlist Dance Machine - Time 3600",
    speed: "3",
    time: "60",
    videos: ["41", "38", "39", "4", "5", "7", "9", "10", "30", "31", "35", "32"],
    actualTime: "3611",
  },
];

export default playlists;
