const videos = [
  {
    id: "1",
    vimeoId: "333003802",
    url: "https://youtu.be/evvAXPEf7zs",
    // url: "https://youtube.com/playlist?list=PL-_Xw0c-5GxQIDL2UPhB2m9ewS__Uq40O",
    title: "Nia: Kirsten Group Class - Song 01",
    speed: "1",
    time: "234",
  },
  {
    id: "2",
    vimeoId: "333003915",
    url: "https://youtu.be/JSG9N2OddXM",
    title: "Nia: Kirsten Group Class - Song 07",
    speed: "2",
    time: "322",
  },
  {
    id: "3",
    vimeoId: "333003992",
    url: "https://youtu.be/tAqQYknEcK4",
    title: "Nia: Hand Slow",
    speed: "2",
    time: "548",
  },
  {
    id: "4",
    vimeoId: "333004143",
    url: "https://youtu.be/YTcGnYL-Y6M",
    title: "Nia: Hand Fast",
    speed: "3",
    time: "255",
  },
  {
    id: "5",
    vimeoId: "333004289",
    url: "https://youtu.be/jlkC_Lz9cQo",
    title: "Danceation: Janet Brock solo",
    speed: "3",
    time: "659",
  },
  {
    id: "6",
    vimeoId: "333004362",
    url: "https://youtu.be/8C9VMg-fSh4",
    title: "Danceation: Heath Group Class - Dance 1 (Warm up)",
    speed: "2",
    time: "294",
  },
  {
    id: "7",
    vimeoId: "333004488",
    url: "https://youtu.be/2To9imAuYsE",
    title: "Danceation: Heath Group Class - Dance 2 (sexy dance)",
    speed: "3",
    time: "201",
  },
  {
    id: "8",
    vimeoId: "333004544",
    url: "https://youtu.be/H25XxbJG6d4",
    title: "Danceation: Heath Group Class - Dance 3 (Be happy)",
    speed: "3",
    time: "238",
  },
  {
    id: "9",
    vimeoId: "333006833",
    url: "https://youtu.be/UP1Np383Xjs",
    title: "Danceation: Heath Group Class - Dance 4 (Hands on your body)",
    speed: "3",
    time: "191",
  },
  {
    id: "10",
    vimeoId: "333006996",
    url: "https://youtu.be/PY67kS-myyc",
    title: "Danceation: Heath Group Class - Dance 5 (With scarves)",
    speed: "3",
    time: "239",
  },
  {
    id: "11",
    vimeoId: "333007070",
    url: "https://youtu.be/1HD0aLo9_X0",
    title: "Danceation: Heath Group Class - Danceation explained & song",
    speed: "2",
    time: "311",
  },
  {
    id: "12",
    vimeoId: "333007138",
    url: "https://youtu.be/LI3aFr0BzIM",
    title: "Dance for PD: Torso and arm warm up_Easy Breezy",
    speed: "1",
    time: "170",
  },
  {
    id: "13",
    vimeoId: "333007195",
    url: "https://youtu.be/8aVLVys8pvs",
    title: "Dance for PD: Plies and port de bras_Easy Breezy",
    speed: "1",
    time: "181",
  },
  {
    id: "14",
    vimeoId: "333007263",
    url: "https://youtu.be/jS_XfUr5m2Y",
    title: "Dance for PD: Swings_Mellow Bellow",
    speed: "2",
    time: "213",
  },
  {
    id: "15",
    vimeoId: "333007342",
    url: "https://youtu.be/lRpZvlxOAN8", //this is the same url as 14 on worksheet
    title: "Dance for PD: Jazzy feet_Mellow Bellow",
    speed: "2",
    time: "188",
  },
  {
    id: "16",
    vimeoId: "333007441",
    url: "https://youtu.be/DAJPG83PI8M",
    title: "Dance for PD: Stars and Stripes _Dance Machine",
    speed: "3",
    time: "264",
  },
  {
    id: "17",
    vimeoId: "333007493",
    url: "https://youtu.be/qSe5Sionw4w",
    title: "Dance for PD: West Side Story Tribute_Dance Machine",
    speed: "3",
    time: "184",
  },
  {
    id: "18",
    vimeoId: "333007581",
    url: "https://youtu.be/7wBuI8ps-Bo",
    title: "Nia: Kristen Group Class - Warmup",
    speed: "1",
    time: "194",
  },
  {
    id: "19",
    vimeoId: "333011170",
    url: "https://youtu.be/juaaszC51rg",
    title: "Nia: Kirsten Group Class - High Energy, Martial Arts Style",
    speed: "3",
    time: "229",
  },
  {
    id: "20",
    vimeoId: "333011262",
    url: "https://youtu.be/BZOpwlvMJao",
    title: "Nia: Kirsten Group Class - Slower Song, No Worries",
    speed: "2",
    time: "195",
  },
  {
    id: "21",
    vimeoId: "333011341",
    url: "https://youtu.be/_oGauAk8K0k",
    title: "Soul Motion: Devorah - Intro",
    speed: "1",
    time: "566",
  },
  {
    id: "22",
    vimeoId: "333011431",
    url: "https://youtu.be/Q6oTnN0mHO0",
    title: "Soul Motion: Devorah - Dance 1",
    speed: "1",
    time: "686",
  },
  {
    id: "23",
    vimeoId: "333011531",
    url: "https://youtu.be/qG-uwnoN4E0",
    title: "Soul Motion: Devorah Group Class - Dance 2 (Partner Dance)",
    speed: "1",
    time: "260",
  },
  {
    id: "24",
    vimeoId: "333011610",
    url: "https://youtu.be/ZqnPsOfCDxk",
    title: "Soul Motion: Devorah- Dance 3 (Walking)",
    speed: "1",
    time: "202",
  },
  {
    id: "25",
    vimeoId: "333011692",
    url: "https://youtu.be/ykM1Vd5LSNc",
    title: "Soul Motion: Devorah Group Class - Dance 4 (Latin, Free Dance)",
    speed: "1",
    time: "198",
  },
  {
    id: "26",
    vimeoId: "333012707",
    url: "https://youtu.be/l32fiAi_RJA",
    title: "Zumba: Melissa - Intro",
    speed: "1",
    time: "331",
  },
  {
    id: "27",
    vimeoId: "333012788",
    url: "https://youtu.be/JancWQLlExY",
    title: "Zumba: Melissa Group Class - Warmup 1",
    speed: "2",
    time: "213",
  },
  {
    id: "28",
    vimeoId: "333012873",
    url: "https://youtu.be/onlml7LQXiw",
    title: "Zumba: Melissa Group Class - Warmup 2",
    speed: "2",
    time: "215",
  },
  {
    id: "29",
    vimeoId: "333012959",
    url: "https://youtu.be/qZUGlglsyt8",
    title: "Zumba: Melissa Group Class - Samba",
    speed: "3",
    time: "263",
  },
  {
    id: "30",
    vimeoId: "333013066",
    url: "https://youtu.be/7Wh9tGDwC8Y",
    title: "Zumba: Melissa Group Class - Dance 2",
    speed: "3",
    time: "215",
  },
  {
    id: "31",
    vimeoId: "333013136",
    url: "https://youtu.be/0j-PkjmMdAQ",
    title: "Zumba: Melissa Group Class - Dance 3",
    speed: "3",
    time: "231",
  },
  {
    id: "32",
    vimeoId: "333013197",
    url: "https://youtu.be/dB73TUSCEco",
    title: "Zumba: Melissa Group Class - Cool Down",
    speed: "1",
    time: "238",
  },
  {
    id: "33",
    vimeoId: "333013285",
    url: "https://youtu.be/jUAGDO_psaU",
    title: "Zumba: Melissa Solo - Dance 1",
    speed: "3",
    time: "280",
  },
  {
    id: "34",
    vimeoId: "333013368",
    url: "https://youtu.be/D05mLUlK0Cg",
    title: "Zumba: Melissa Solo - Dance 2",
    speed: "3",
    time: "186",
  },
  {
    id: "35",
    vimeoId: "333013482",
    url: "https://youtu.be/PTbKgVffSoY",
    title: "Zumba: Melissa Solo - Dance 3",
    speed: "3",
    time: "180",
  },
  {
    id: "36",
    vimeoId: "333013530",
    url: "https://youtu.be/pCJ9f2KIBs4",
    title: "What is Bollywood Dance?",
    speed: "1",
    time: "219",
  },
  {
    id: "37",
    vimeoId: "333013603",
    url: "https://youtu.be/V5awVlaYaaM",
    title: "Bollywood Intro Moves + Dance",
    speed: "2",
    time: "379",
  },
  {
    id: "38",
    vimeoId: "333013692",
    url: "https://youtu.be/lwFt0PJTIyQ",
    title: "Bollywood ShruthiReddy HighEnergy",
    speed: "3",
    time: "169",
  },
  {
    id: "39",
    vimeoId: "333013754",
    url: "https://youtu.be/7MCVmLrHtnI",
    title: "Bollywood ShruthiReddy HighEnergy Dance2",
    speed: "3",
    time: "179",
  },
  {
    id: "40",
    vimeoId: "333013833",
    url: "https://youtu.be/b6Uln0cJvM0",
    title: "Egyptian Dance Janette Najera Low Energy",
    speed: "1",
    time: "281",
  },
  {
    id: "41",
    vimeoId: "333013927",
    url: "https://youtu.be/JjEmWzKfUPo",
    title: "UJam_AmyCRad_Warmup rough cut",
    speed: "3",
    time: "833",
  },
];

export default videos;
