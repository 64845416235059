import React from 'react';
import '../../css/emotion.css';

const EmotionMessage = ({ heading, message, handleEmotionMessageClick }) => {

    return(
        // <div id="horizon2" className="horizon2">
        // <div id="center3" className="center3">
          
          <div
            id="message_overlay"
            onClick={() => handleEmotionMessageClick()}
            className="overlay"
          >
              <h2 id='emotion-message-h2'>{heading}</h2>
            <p
              id="message"
              dangerouslySetInnerHTML={{ __html: message }}
            />
            <p id="closeLink" className="bottom-message">
              Click to close
            </p>
          </div>
        
        // </div>
        // </div>
    )
}

export default EmotionMessage;