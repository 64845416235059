export const getMessage = (emotionId) => {
    switch (emotionId) {
      case "btn_happy":
        return {
          emotion: "happy",
          heading: "Happy",
          message:
            "We're ecstatic that you are happy!<br />Let's rock the party!",
        };
      case "btn_sad":
        return {
          emotion: "sad",
          heading: "Sad",
          message: "Sad? Let's see if we can fix that!",
        };
      case "btn_tired":
        return {
          emotion: "tired",
          heading: "Tired",
          message: "Tired? Music and dancing will lift you up! :)",
        };
      case "btn_angry":
        return {
          emotion: "angry",
          heading: "Angry",
          message:
            "Oh, I'm sorry to hear that. <br />Let's see if we can cool you down!",
        };
      case "btn_worried":
        return {
          emotion: "worried",
          heading: "Worried",
          message: "Don't Worry. Everything will be ok :)",
        };
      case "btn_scared":
        return {
          emotion: "scared",
          heading: "Scared",
          message: "The only thing to fear is fear itself - FDR",
        };
      default:
        return {
          emotion: "default",
          heading: "Unsure",
          message: "This is the default emotion message",
        };
    }
  }