import { Link } from "react-router-dom";
import "../assets/css/bootstrap.scoped.css";

const NavBar = () => {
  return (
    <nav className="navbar navbar-default sticky-top">
      <div id="fb-root" />
      {/* Facebook Login */}
      <script
        src="https://connect.facebook.net/en_US/sdk.js?hash=f842513cfa7170782bb13b1dff0d0fa9&amp;ua=modern_es6"
        async=""
        crossOrigin="anonymous"
      />
      <script
        id="facebook-jssdk"
        src="//connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v2.5"
      />
      <script>
        {
          ' (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s);js.id = id;js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5";fjs.parentNode.insertBefore(js, fjs);}(document, "script", "facebook-jssdk"));'
        }
      </script>
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#navbar"
            aria-expanded="false"
            aria-controls="navbar"
          >
            <span className="sr-only">Toggle Navigation</span>
            <span className="icon-bar" />
            <span className="icon-bar" />
            <span className="icon-bar" />
          </button>
          <Link className="navbar-brand" to="/">
            Dance4healing
          </Link>
        </div>
        <div id="navbar" className="collapse navbar-collapse" style={{ fontWeight: "500 !important" }}>
          <ul className="nav navbar-nav">
            <li>
              <Link to="/register">Join the Party</Link>
            </li>
            <li className="active">
              <Link to="/login">Log In</Link>
            </li>
          </ul>
          <ul className="nav navbar-nav navbar-right" />
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
