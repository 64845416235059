import React from "react";
import "../../css/emotion.css";

const EmojiMessage = ({ heading, emotion, handleClick }) => {
  let emojiDisplay;

  const goodEmotionArray = [
    require("../../assets/img/emoji-congrats.png"),
    require("../../assets/img/emoji-danceparty.png"),
    require("../../assets/img/emoji-festival.png"),
    require("../../assets/img/emoji-glasses.png"),
    require("../../assets/img/emoji-keepgoing.png"),
    require("../../assets/img/emoji-letsparty.png"),
    require("../../assets/img/emoji-tenoutoften.png"),
    require("../../assets/img/emoji-partytime.png"),
  ];
  const badEmotionArray = [
    require("../../assets/img/emoji-beatbox.png"),
    require("../../assets/img/emoji-confetti.png"),
    require("../../assets/img/emoji-heart.png"),
    require("../../assets/img/emoji-dancing.png"),
    require("../../assets/img/emoji-goodjob.png"),
    require("../../assets/img/emoji-killinit.png"),
    require("../../assets/img/emoji-waytogo.png"),
    require("../../assets/img/emoji-youdidthething.png"),
    require("../../assets/img/emoji-woot.png"),
  ];

  if (emotion === "btn_happy") {
    const index = Math.floor(Math.random() * goodEmotionArray.length);
    emojiDisplay = goodEmotionArray[index];
  } else {
    const index = Math.floor(Math.random() * badEmotionArray.length);
    emojiDisplay = badEmotionArray[index];
  }

  return (
    <div id="emotion-modal-outer" onClick={() => handleClick()}>
      <div className="emoji-modal-message" id="emoji-modal-message-top">
      Great Job! You completed the session!
      </div>
      <div id="emotion-modal-inner">
          <img id="emoji" src={emojiDisplay} />
      </div>
      <div className="emoji-modal-message" id="emoji-modal-message-bottom">
        Click to close
      </div>
    </div>
  );
};

export default EmojiMessage;
