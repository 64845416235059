import btn_mail from "../assets/img/btn_mail.png";
import btn_heart from "../assets/img/btn_heart.png";
import btn_facebook from "../assets/img/btn_facebook.png";
import btn_twitter from "../assets/img/btn_twitter.png";
import btn_linkedin from "../assets/img/btn_login_linkedin.png"

const FooterWithLinks = () => {
  return (
    <footer>
      <div className="icons">
        <a href="mailto:hello@dance4healing.com" target="_blank">
          <img src={btn_mail} alt="Mail" />
        </a>
        <a href="https://www.linkedin.com/company/dance4healing" target="_blank">
          <img src={btn_linkedin} alt="LinkedIn" />
        </a>
        <a href="https://www.facebook.com/danceforhealing?fref=ts" target="_blank">
          <img src={btn_facebook} alt="Facebook" />
        </a>
        <a href="http://www.twitter.com/dance4healing" target="_blank">
          <img src={btn_twitter} alt="Twitter" />
        </a>
      </div>
      <p>
        Copyright © Dance4Healing 2024
        <br />
        Best viewed in{" "}
        <a href="https://www.google.com/chrome/browser/desktop/">
          Chrome web browser
        </a>
        .
      </p>
    </footer>
  );
};

export default FooterWithLinks;
