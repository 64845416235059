import login_facebook from "../../assets/img/btn_login_facebook.png";
import login_twitter from "../../assets/img/btn_login_twitter.png";
import login_google from "../../assets/img/btn_login_google.png";
import login_linkedin from "../../assets/img/btn_login_linkedin.png";
import * as usersAPI from "../../utils/usersAPI";
import React, { useEffect, useState } from "react";

const NotVerified = ({ username, handleResendSuccess }) => {

  const handleResendConfirm = () => {
    const result = usersAPI.sendConfirm(username.toLowerCase());
   //TODO: we should verify that the send confirm was successful
    handleResendSuccess();
  };

  return (
    <div
      className="confirmation-message"
      style={{
        display: "block",
        width: "200%",
        height: "100%",
        position: "fixed",
        bottom: "50%",
        right: "50%",
        transform: "translate(50%, 50%)",
        backgroundColor: "#CC3366",
        textAlign: "left",
      }}
    >
      <div
        className="confirmation-message-text"
        style={{
          position: "relative",
          top: "50%",
          transform: "translate(0, -50%)",
          marginLeft: "30%",
          marginRight: "30%",
          lineHeight: 2,
          fontSize: 50,
        }}
      >
        <div>
          <h2 style={{ fontWeight: "bold" }}>
            You have not confirmed your email yet.
          </h2>
          <p>Dance4Healing ensures high-quality connections.</p>
          <p>
            Please go to your mailbox and confirm your email address to access
            your account.
          </p>
          <p>
            Check your spam if you don't see it. If needed, please click{" "}
            <button onClick={handleResendConfirm} className="btn-lg register2">
              Resend
            </button>{" "}
            to resend verification.
          </p>
          <div style={{ textAlign: "center" }}>
            <p>
              Meanwhile, follow us on these social media websites down below!
            </p>
            <div className="icons">
              <a href="https://www.facebook.com/danceforhealing">
                <img src={login_facebook} alt="Facebook" />
              </a>
              <a href="http://www.twitter.com/dance4healing">
                <img src={login_twitter} alt="Twitter" />
              </a>
              <a href="https://www.linkedin.com/company/dance4healing">
                <img src={login_linkedin} alt="LinkedIn" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotVerified;
