import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import * as usersAPI from '../utils/usersAPI';
import serializeForm from 'form-serialize';

function PasswordReset(props) {

    const [message, setMessage] = React.useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = serializeForm(e.target, { hash: true })
        values.userinfo = values.userinfo.toLowerCase(); //to convert the email id to lowercase
        const result = await usersAPI.resetPass({
            values
        });
        if (result.success === true) {
            setMessage(result.Message);
        }
        else {
            setMessage(result.errorMessage);
        }
    };

    return (
        <div>
            <nav className="navbar navbar-default navbar-fixed-top " >
                <div id="fb-root"></div>
                {/* Facebook Login */}
                <script src="https://connect.facebook.net/en_US/sdk.js?hash=f842513cfa7170782bb13b1dff0d0fa9&amp;ua=modern_es6"
                    async="" crossOrigin="anonymous"></script><script id="facebook-jssdk"
                        src="//connect.facebook.net/en_US/sdk.js#xfbml=1&amp;version=v2.5"></script>
                <script>
                    {' (function(d, s, id) {var js, fjs = d.getElementsByTagName(s)[0];if (d.getElementById(id)) return;js = d.createElement(s);js.id = id;js.src = "//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.5";fjs.parentNode.insertBefore(js, fjs);}(document, "script", "facebook-jssdk"));'}
                </script>
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="navbar-toggle collapsed" data-toggle='collapse' data-target='#navbar' aria-expanded='false' aria-controls='navbar'>
                            <span className="sr-only">Toggle Navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                        <Link className="navbar-brand" to="/">Dance4healing</Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse">
                        <ul className="nav navbar-nav">
                            <li><Link to="/register">Join the Party</Link>
                            </li>
                            <li className="active"><Link to="/login">Log In</Link>
                            </li>
                        </ul>
                        <ul className="nav navbar-nav navbar-right" />
                    </div>
                </div>
            </nav>

            <div style={{ marginTop: 75, textAlign: 'center', width: 380 }} className="container">
                <h1>Forgot Password?</h1>
                <p style={{ marginTop: 30, marginBottom: 30, fontFamily: '"Lato", sans-serif', fontStyle: 'italic', fontSize: 24, fontWeight: 300, letterSpacing: 1 }} >Don't panic, we'll send you a link
                    to reset your password.</p>
                <p style={{ paddingBottom: 12, color: 'white', fontFamily: '"Lato", sans-serif', fontSize: 24, fontWeight: 300, letterSpacing: 1 }} >{message}</p>
                <div data-max-rows={1} data-size="xlarge" data-show-faces="false" data-auto-logout-link="false" className="fb-login-button" />
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label style={{ float: 'left', fontFamily: '"Roboto", sans-serif' }}>Email / Username</label>
                        <input name="userinfo" type="text" className="form-control container" placeholder="Enter Email / Username" />
                        <input name="appurl" type="hidden" value={window.location.href} />
                    </div>

                    <button type="submit" name="submit" className="btn-lg register2" style={{ width: '100%' }}>Reset Password</button>
                    <p style={{ textAlign: 'center', paddingTop: 18, fontSize: 24, paddingBottom: 18, borderTopStyle: 'dotted', borderWidth: 2, marginTop: 24, fontFamily: '"Lato", sans-serif', fontWeight: 300, letterSpacing: 1 }}>No account and want to dance?</p><a href="/users/register" role="button" className="btn btn-lg login2">Join the Party</a>
                </form>
            </div>
            <footer>
                <p className="smallfooter">Copyright © Dance4Healing 2023</p>
                <p className="smallfooter">Best viewed in <a href="https://www.google.com/chrome/browser/desktop/">Chrome web browser.</a>
                </p>
            </footer>
        </div>
    )

}

export default PasswordReset;