import React, { useState } from "react";
import "../../css/emotion.css";
import crowdSound from "../../assets/audio/crowd-cheer-ii-6263.mp3";
import applause from "../../assets/audio/Cheers_audio.m4a";
const audio = new Audio(applause);

export const FinishedDancingModal = ({ handleModalResponse }) => {
  const [displayQuestions, setDisplayQuestions] = useState(true);
  const [displayCongrats, setDisplayCongrats] = useState(false);
  const [displayKeepGoing, setDisplayKeepGoing] = useState(false);

  const handleResponse = (response) => {
    setDisplayQuestions(false);
    response === "yes" ? setDisplayCongrats(true) : setDisplayKeepGoing(true);
    setTimeout(() => {
      //audio.pause();
      //audio.currentTime = 0;
      response === "yes"
        ? setDisplayCongrats(false)
        : setDisplayKeepGoing(false);
      handleModalResponse(response);
    }, 6000);
  };

  const finishDisplay = () => {
    return (
      <div id="question-modal">
        <h1>Did you finish your dance?</h1>
        <div id="buttons">
          <div>
            <img
              className="modal-btn"
              onClick={() => handleResponse("yes")}
              src={require("../../assets/img/Btn_Yes.png")}
            />
            <p>Congratulations!</p>
          </div>
          <div>
            <img
              className="modal-btn"
              onClick={() => handleResponse("no")}
              src={require("../../assets/img/Btn_No.png")}
            />
            <p>Continue the dance</p>
          </div>
        </div>
      </div>
    );
  };

  const congratsEmoji = () => {
    audio.volume = 0.18;
    audio
      .play()
      .then(
        setTimeout(() => {
          //audio.pause();
          //console.log("timeout of 5 seconds hit!!!");
        }, 5000)
      )
      .catch((error) => {
        console.log("error while playing audio:", error);
      });
    return (
      <div id="emotion-modal-outer">
        <div className="emoji-modal-message" id="emoji-modal-message-top">Keep on rocking!</div>
        <div id="emotion-modal-inner">
          <img
            id="emoji"
            src={require("../../assets/img/Emoji_WellDone.png")}
          />
        </div>
        <div className="emoji-modal-message" id="emoji-modal-message-bottom" />
      </div>
    );
  };

  const keepGoingEmoji = () => {
    return (
      <div id="emotion-modal-outer">
        <div className="emoji-modal-message" id="emoji-modal-message-top">Keep on rocking!</div>
        <div id="emotion-modal-inner">
          <img
            id="emoji"
            src={require("../../assets/img/Emoji_KeepGoing.png")}
          />
        </div>
        <div className="emoji-modal-message" id="emoji-modal-message-bottom" />
      </div>
    );
  };

  return (
    <>
      {displayQuestions || displayCongrats || displayKeepGoing}
      <div id="emotion-modal">
        {displayQuestions ? finishDisplay() : null}
        {displayCongrats ? congratsEmoji() : null}
        {displayKeepGoing ? keepGoingEmoji() : null}
      </div>
    </>
  );
};
