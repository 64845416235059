import React, { Component } from 'react';
import * as usersAPI from '../utils/usersAPI';
import serializeForm from 'form-serialize';
import { Navigate } from 'react-router-dom';
import "../assets/css/validation.scoped.css";
import login_facebook from "../assets/img/btn_login_facebook.png";
import login_twitter from "../assets/img/btn_login_twitter.png";
import login_linkedin from "../assets/img/btn_login_linkedin.png";

class SignupForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      confirmation: null,
      redirect: null,
      passError: ''
    }
  }

  handleSubmit = async e => {
    e.preventDefault();
    const values = serializeForm(e.target, { hash: true })
    values.email = values.email.toLowerCase(); //to convert the email id to lowercase
    
    const res = await usersAPI.registerUser({ values })
    
    if (res.errorMessage) {
      const element = <p className="borderError" >{res.errorMessage}</p>
      this.props.onMessageChange(element);
    } else {
      const element = res.errorMessage
      this.props.onMessageChange(element);
    }
    
    if ('details' in res) {
      if (res.details[0].param === 'password') {
        this.setState({ passError: res.details[0].msg });
      }
    }
    
    if (res != null && 'Message' in res && res.Message === "Signup successfully!") {
      setTimeout(() => {  
        this.setState({ redirect: "/login" })
        }, 25000)
        this.setState({confirmation: 'block'});
    }

    const { email } = values
    window.localStorage.clear(); // logs out user if previously logged in
    window.localStorage.setItem('email', email);
    usersAPI.sendConfirm(email)
  }


  render() {
    if (this.state.redirect) {
      return <Navigate replace to={this.state.redirect} />
    }
    else if (this.state.confirmation){
      return(
        <div className="confirmation-message" style={{display: 'block', width: '200%', height: '100%', position: 'fixed', bottom: '50%', right: '50%',  transform: 'translate(50%, 50%)', backgroundColor: '#CC3366', textAlign: 'left'}}>
          <p className = "confirmation-message-text" style = {{position: 'relative', top: '50%', transform: 'translate(0, -50%)', marginLeft: '30%', marginRight: '30%', lineHeight:2, fontSize:50}}>
            <div style={{textAlign:"center"}}>
              <h2>Thank you for signing up for Dance4Healing!</h2>
              <p>We can't wait to dance with you.</p>
              <p>We've sent a verification email to your email. Click on the activation link in your inbox.</p>
              <p>If you haven't received this email, please wait a few minutes or check your junk or spam folder.</p>
              <p>Still having trouble? Email hello@dance4healing.com for help.</p>
              <p>We respect your privacy. Here is our <a href="https://d4hv3.herokuapp.com/privacy.pdf" style={{textDecoration:"underline"}}>Privacy Policy.</a></p>
              <p>Meanwhile, follow us on these social media website down below!</p>
              <div className="icons">
                  <a href="https://www.facebook.com/danceforhealing?fref=ts"><img src={login_facebook} alt="Facebook" /></a>
                  <a href="http://www.twitter.com/dance4healing"><img src={login_twitter} alt="Twitter" /></a> 
                  <a href="https://www.linkedin.com/company/dance4healing"><img src={login_linkedin} alt="LinkedIn"/></a>
              </div>
            </div>
          </p>
        </div>
      )
    }
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="form-group">
          <label style={{ marginLeft: '-273px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold'}}>First Name</label>
          <input id="fname" name="fname" type="text" placeholder="Enter First Name" className="form-control container" />
        </div>
        <div className="form-group">
          <label style={{ marginLeft: '-276px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Last Name</label>
          <input id="lname" name="lname" type="text" placeholder="Enter Last Name" className="form-control container" />
        </div>
        <div className="form-group">
          <label style={{ marginLeft: '-310px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Email</label>
          <input id="email" name="email" type="email" placeholder="Email" className="form-control container" />
        </div>
        <div className="form-group tooltip">
          <label style={{ marginLeft: '-283px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Password</label><p style={{
            display: 'inline-block',
            color: 'yellow', marginLeft: '5px', fontSize: 'small'
          }}>{this.state.passError}</p>
          <input id="password" name="password" type="password" placeholder="Enter Password" className="form-control container" /><span className="tooltiptext">Password requirements are: 8 characters min length.<br></br> At least 1 capital letter.<br></br> At least 1 number.<br></br> At least 1 symbol.</span>
        </div>
        <div className="form-group">
          <label style={{ marginLeft: '-232px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Confirm Password</label>
          <input id="password2" name="password2" type="password" placeholder="Confirm Password" className="form-control container" />
        </div>
        <div className="form-group">
          <label style={{ marginLeft: '-224px', fontFamily: 'Roboto, sans-serif', fontWeight: 'bold' }}>Type your Affiliation</label>
          <input id="affiliation" name="affiliation" type="text" placeholder="Type in your organization name if you belong to one" className="form-control container" />
        </div>
        {/* CHECK BOXES */}
        <div style={{marginTop: 20 , width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'center'}}>
          <div>
            <p style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 'bold', fontSize: 15, textAlign: 'left' }}>
              <input type="checkbox" id="instructor" name="instructor" /> Are you an instructor that want to teach?</p>
          </div>
          <div style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 300, fontSize: 15, textAlign: 'left'}}>
            <input type="checkbox" id="newsletter" name="newsletter" style={{ paddingTop: 25, marginTop: 15 }} /> Sign me up for the newsletter, I like to stay informed.
          </div>

          <div style={{ fontFamily: 'Roboto, sans-serif', fontWeight: 300, fontSize: 15, textAlign: 'left'}}>
            <input type="checkbox" id="toagree" name="toagree" style={{ paddingTop: 25, marginTop: 15 }} /> I agree to the Dance4Healing <a href="/terms.pdf" style={{ textDecoration: 'underline' }}>Terms of Service</a> and <a href="/privacy.pdf" style={{ textDecoration: 'underline' }}> Privacy Policy</a>.
          </div>
          <br />
        </div>

        <button style={{fontFamily: 'Roboto, sans-serif', fontWeight: 'bold'}} type="submit" name="submit" id="submit" className="btn-lg register2">Sign Up Now</button>
        
      </form>
    )
  }
}

export default SignupForm;
